import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Header from "../components/Header"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Main from "../components/Main"
import Title from "../components/Title"
import BodyWrapper from "../components/BodyWrapper"

const browser = typeof window !== "undefined" && window

const Typography = styled.p`
  text-align: center;
`

const NotFoundPage = () =>
  browser && (
    <Layout>
      <SEO title="404: Not found" />
      <Main>
        <Header>
          <Title text="404 Not Found" to="/" />
        </Header>
        <BodyWrapper>
          <Typography>
            Looks like you&#39;ve landed in the unknown... let&#39;s go back{" "}
            <Link>home</Link>
          </Typography>
        </BodyWrapper>
      </Main>
    </Layout>
  )

export default NotFoundPage
