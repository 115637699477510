import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const TitleText = styled.h1`
  justify-content: flex-end;
  text-decoration: none;
  display: flex;
  border-left: 0.2em solid lightskyblue;
  white-space: nowrap;
  letter-spacing: 0.05em;
  margin: 0.2rem;
  padding-left: 0.2rem;
  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 1.6rem;
  }
  &:hover {
    border-left-color: orange;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`

const Title = ({ to, text }) => (
  <TitleText>
    <StyledLink to={to}>{text}</StyledLink>
  </TitleText>
)

export default Title
