import styled from "styled-components"

const Main = styled.div`
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (min-width: 480px) {
    padding-top: 30vh;
  }
`

export default Main
