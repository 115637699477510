import styled from "styled-components"

const BodyWrapper = styled.div`
  text-align: justify;
  width: 85%;
  @media (min-width: 768px) {
    width: 55%;
  }
`

export default BodyWrapper
